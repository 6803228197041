import { graphql, navigate } from 'gatsby';
import { Box, Layer, Spinner } from 'grommet';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Main from '../components/main/main';
import { getItem, StorageKeys } from '../services/storage-service';
import { storeUser } from '../state/modules/data';
import { parseJwt } from '../utils/parseJwt';

// markup
const IndexPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams?.get('uid');

    if (id) {
      dispatch(storeUser({ id }));
    } else {
      const user = getItem(StorageKeys.USER);
      if (user?.id) {
        dispatch(storeUser(user));
      } else {
        const fakeId = uuidv4();
        dispatch(storeUser({ id: fakeId }));
      }
    }

    navigate('/app');
  }, [dispatch]);

  return (
    <>
      <Layer animate={false}  >
        <Box justify='center' align='center'>  <Spinner size={'medium'} alignSelf='center' justify='center' /></Box>
      </Layer>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { in: [$language, "en"] } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
